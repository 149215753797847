import _ from 'lodash';
import './../sass/main.scss';

window.$ = window.jQuery = require('jquery');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
import 'jquery-validation';

require ('./typographer/jquery.typographer.min.pack.js');
require ('./typographer/hyph-pl.min.js');

require('slick-carousel');

require ('../../node_modules/slicknav/jquery.slicknav.js');

jQuery.noConflict();

jQuery(document).ready(function(){

	jQuery('body img').addClass('img-fluid');

	jQuery('body').typographer_orphan({
	  forbidden: ["a", "i", "o", "u", "w", "z", "oraz", "dla"]
	});

	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
	  return new bootstrap.Popover(popoverTriggerEl)
	});

	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	  return new bootstrap.Tooltip(tooltipTriggerEl)
	});

	//Sticky START

	//var stickyNavTop = $('.nav').offset().top;
	var stickyNavTop = 140;

	// our function that decides weather the navigation bar should have "fixed" css position or not.
	var stickyNav = function(){
		var scrollTop = jQuery(window).scrollTop(); // our current vertical position from the top

		// if we've scrolled more than the navigation, change its position to fixed to stick to top,
		// otherwise change it back to relative
		if (scrollTop > stickyNavTop) {
			jQuery('#main-top-1').addClass('main-sticky');
		} else {
			jQuery('#main-top-1').removeClass('main-sticky');
		}
	};

	stickyNav();
	// and run it again every time you scroll
	jQuery(window).scroll(function() {
		stickyNav();
	});

	//Sticky STOP

	jQuery("#main-contact-form").validate({
		rules: {
			email: {
				required: function(element) {
					if (jQuery("#phone_1").val().length > 0) {
						return false;
					} else {
						return true;
					}
				},
				email: true
			},
			phone: {
				required: function(element) {
					if (jQuery("#email_1").val().length > 0) {
						return false;
					} else {
						return true;
					}
				},
				minlength: 6,
				maxlength: 60
			},
			data_reg: {
				required: true
			}
		},
		messages: {
			email: {
				required: "E-Mail oder Telefon ist erforderlich!",
				email: "Ungültige E-Mail!"
			},
			phone: {
				required: "Telefon oder E-Mail ist erforderlich!",
				minlength: "Ungültiges Telefon!",
				maxlength: "Ungültiges Telefon!"
			},
			data_reg: {
				required: "Sie müssen der Verarbeitung personenbezogener Daten zustimmen!"
			}
		}
	});

	jQuery("#main-contact-form-2").validate({
		rules: {
			email: {
				required: function(element) {
					if (jQuery("#phone_2").val().length > 0) {
						return false;
					} else {
						return true;
					}
				},
				email: true
			},
			phone: {
				required: function(element) {
					if (jQuery("#email_2").val().length > 0) {
						return false;
					} else {
						return true;
					}
				},
				minlength: 6,
				maxlength: 60
			},
			data_reg: {
				required: true
			}
		},
		messages: {
			email: {
				required: "E-Mail oder Telefon ist erforderlich!",
				email: "Ungültige E-Mail!"
			},
			phone: {
				required: "Telefon oder E-Mail ist erforderlich!",
				minlength: "Ungültiges Telefon!",
				maxlength: "Ungültiges Telefon!"
			},
			data_reg: {
				required: "Sie müssen der Verarbeitung personenbezogener Daten zustimmen!"
			},
		}
	});

	jQuery('#modal-send-form').modal('show');

	//SlickCarousel a START

	jQuery('.main-carousel').slick({
		autoplay: false,
		arrows: true,
		dots: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		prevArrow: '<a href="javascript:void(0);" id="crl-prev" class="crl-arrows s-animate-two s-animate-two--scale"><img src="assets/media_and_docs/prev-one.svg" alt="Arrow"></a>',
		nextArrow: '<a href="javascript:void(0);" id="crl-next" class="crl-arrows s-animate-two s-animate-two--scale"><img src="assets/media_and_docs/next-one.svg" alt="Arrow"></a>',
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
		]
	});

	jQuery('.main-carousel-area').each(function (index_a) {
		var carouselArea = jQuery(this);
		jQuery(this).find('.carousel-info').each(function (index_b) {
			if(!index_b) jQuery(this).css('display', 'flex');
		});
		jQuery(this).parent().find('.main-carousel').find('.crl-item').click(function (e) {
			e.preventDefault();

            var crlHref = jQuery(this).attr('href');
			if(carouselArea.find(crlHref).css('display') === 'none') {
                carouselArea.find('.carousel-info').fadeOut(500);
                setTimeout(function () {
                    carouselArea.find(crlHref).css('display', 'flex');
                }, 500);
            }
			setTimeout(function () {
				jQuery('html, body').animate({scrollTop: (carouselArea.offset().top - 50)}, 'slow');
			}, 500);
		});
	});

	//SlickCarousel a STOP

	//Show/hide info START
	jQuery('.main-btn-secret').each(function (index) {
		var mainBtn = jQuery(this);
		jQuery(this).click(function (e) {
			e.preventDefault();
			mainBtn.parent().find('.info-show-hide').first().hide();
			mainBtn.parent().find('.info-secret').first().css('display', 'inline-block');
			mainBtn.fadeOut('500');
		});
	});
	//Show/hide info STOP

	mainResize();

	jQuery("#menu-top-container > .menu-top").slicknav({
		prependTo: '#main-top-1',
		label: '',
		closeOnClick: true,
		allowParentLinks: true,
	});

});

window.onload = function () {
	jQuery('#main-loader').remove();
    //Rozmiar mapy
    if(jQuery('#main-col-footer-1 iframe').first().length) jQuery('#main-col-footer-1 iframe').first().attr('width', '100%').attr('height', '100%');
	//setHeightMap();
};

jQuery(window).resize(function() {
	mainResize();
});


//Funkcje

function mainResize() {

	var wwidth = jQuery(window).width();
    if (wwidth <= 1200) {
        setHeaderHeight(.75);
        if(wwidth <= 767) setHeaderHeight(1.4);
	} else {
        setHeaderHeight(.5);
	}
}

function setHeaderHeight(factor = 0) {
	var itemHeader = jQuery('#main-top-2');
	var headerWidth = itemHeader.width();
    if(factor !== -1) {
        if(!factor) itemHeader.height((headerWidth*.599));
        else itemHeader.height((headerWidth*factor));
    } else itemHeader.height('auto');
}

function setHeightMap() {
	jQuery('#mapa-kontakt').height(jQuery('footer').first().height());
}

function placementObjectBefore(element, elementBefore) {
	jQuery(elementBefore).before(jQuery(element));
}

function placementObjectAfter(element, elementAfter) {
	jQuery(elementAfter).after(jQuery(element));
}

function placementObjectAppend(element, elementAppend) {
	jQuery(elementAppend).append(jQuery(element));
}

function placementObjectPrepend(element, elementPrepend) {
	jQuery(elementPrepend).prepend(jQuery(element));
}
